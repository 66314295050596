import { Chip } from '@mui/material'
import { PricingRule } from 'api/common'
import { useTranslate } from 'ra-core'
import { List, Datagrid, FunctionField, NumberField } from 'ra-ui-materialui'
import React, { ReactNode } from 'react'

import 'styles.scss'

interface PriceListProps {
  isSingle: boolean,
  children: ReactNode,
}

export const PriceList = ({ children }: PriceListProps) => {
  const translate = useTranslate()

  return <List
    resource={'pricingrules/single'}
    hasCreate={false}
    hasEdit={false}
    hasShow={false}
    exporter={false}
    actions={false}
    hasList={false}
    pagination={false}
    className='no-shadow'
  >
    <Datagrid
      resource={'pricingrules/single'}
      bulkActionButtons={false}
      className='assigments-datagrid small'
    >
      <FunctionField
        label='Stage'
        render={({ stages }: PricingRule) => <Chip className='squere-chip' label={translate(`stage.type.${stages[0]}`)} />}
      />
      <NumberField
        source='price'
        label='Price Per Word'
        textAlign='right'
        sortable={false}
        options={{ style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 15 }}
      />
      {children}
    </Datagrid>
  </List>
}