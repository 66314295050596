
import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { Datagrid, Identifier, List, NumberField, TextField, useDataProvider, useRecordContext } from 'react-admin'

import 'styles.scss'

const Tempo = ({userId} : {userId: Identifier}) => {
  const [paramValue, setParamValue] = useState<number | null>(null)

  const dataProvider = useDataProvider()

  useEffect(() => {
    dataProvider
      .getOne(`features/parameters`, { id: 'TempoCalculationAssignmentsLimit' })
      .then(({ data }) => { setParamValue(data.value) })
  }, [])

  return <>
    <Box margin="60px 0px">
      <List
        resource={`users/${userId}/tempo`}
        title="/tempo"
        exporter={false}
        actions={false}
        pagination={false}
      >
        <Datagrid bulkActionButtons={false} className="assigments-datagrid small-row">
          <TextField source="sourceLanguage" label='Source' sortable={false} />
          <TextField source="targetLanguage" label='Target' sortable={false} />
          <TextField source="assignmentType" label='Type' sortable={false} />
          <NumberField source="wordsPerHour" label='Tempo (total) [words per hour]' locales={'pl-PL'}
            sortable={false} options={{ maximumFractionDigits: 0, useGrouping: true }} />
          <NumberField source="wordsPerHourLastYear" label={`Tempo (last ${paramValue} assignments) [words per hour]`} locales={'pl-PL'}
            sortable={false} options={{ maximumFractionDigits: 0, useGrouping: true }} />
        </Datagrid>
      </List>
    </Box>
  </>
}

export default Tempo

