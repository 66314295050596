import React from 'react'
import { Show, TabbedShowLayout, Tab, useRecordContext } from 'react-admin'

import Invoices from './showTabs/Invoices'
import Profile from './showTabs/Profile'
import PricingRules from './showTabs/PricingRules'
import { CompanyRecord } from 'api/company'
import Members from './showTabs/Members'
import UserService from 'UserService'

import 'styles.scss'
import PortalProperties from './showTabs/PortalProperties'
import MetricsTable from './showTabs/Stats'
import { Role } from 'api/common'

const CompanyShow = () => {
  return <Show title={<CompanyTitle />} component="div" className='user-panel'>
    <CompanyTabbed />
  </Show>
}

const CompanyTabbed = () => {
  const record = useRecordContext()
  if(!record) return null

  const visibleForRoles = (roles: Role[]) => UserService.hasRoles(roles) ? {} : { display: 'none' }
  const hidden = visibleForRoles(['ROLE_PLATFORM_MANAGER', 'ROLE_COMMUNITY_MANAGER', 'ROLE_COMMUNITY_MANAGER', 'ROLE_SUPPORT'])
  
  return <TabbedShowLayout className='gray-skin'>
    <Tab label="Profile">
      <Profile id={record.id} />
    </Tab>
    <Tab label="Members" path="members" style={hidden}>
      <Members />
    </Tab>
    <Tab label="Invoices" path="invoices" style={hidden}>
      <Invoices />
    </Tab>
    <Tab label="Stage Prices" path="pricingrules/single" style={hidden}>
      <PricingRules type='single' record={record} />
    </Tab>
    <Tab label="Bundle Prices" path="pricingrules/multi" style={hidden}>
      <PricingRules type='multi' record={record} />
    </Tab>
    <Tab label="Portal" path="portal-properties">
      <PortalProperties companyId={record.id.toString()} />
    </Tab>
    <Tab label="Stats" path="stats" style={visibleForRoles(['ROLE_PLATFORM_MANAGER', 'ROLE_BI'])}>
      <MetricsTable companyId={record.id.toString()} />
    </Tab>
  </TabbedShowLayout>
}

const CompanyTitle = () => {
  const record = useRecordContext<CompanyRecord>()
  return <span>{record?.name}</span>
}

export default CompanyShow