import React from 'react'
import { useRecordContext } from 'react-admin'
import { Box } from '@mui/material'

import UserService from 'UserService'
import ChangeExecutor from './actions/ChangeExecutor'
import InterruptAssignment from './actions/InterruptAssignment'
import { AssignmentFull } from 'api/assignments'
import ChangePrice from './actions/ChangePrice'
import ReminderTicket from './actions/ReminderTicket'
import RevertExecutor from './actions/RevertExecutor'

const AssignmentShowActions = () => {
  const record = useRecordContext<AssignmentFull>()

  if(!record) return null

  return <Box display='flex' gap="10px" flexWrap='wrap'>
    {UserService.hasRoles(['ROLE_PLATFORM_MANAGER', 'ROLE_GATED_COMMUNITY_MANAGER']) &&
      (record.status === 'ACCEPTED' || record.status === 'PENDING') &&
       <ChangeExecutor assignmentId={record.id} fileName='' />}

    {UserService.hasRoles(['ROLE_PLATFORM_MANAGER', 'ROLE_GATED_COMMUNITY_MANAGER']) &&
      (record.status === 'ACCEPTED') &&
       <RevertExecutor assignmentId={record.id}/>}
       
    {UserService.hasRoles(['ROLE_PLATFORM_MANAGER']) &&
      record.status === 'ACCEPTED' &&
       <InterruptAssignment assignmentId={record.id} />}

    {UserService.hasRoles(['ROLE_SUPPORT', 'ROLE_PLATFORM_MANAGER', 'ROLE_COMMUNITY_MANAGER']) &&
      (record.status === 'ACCEPTED' || record.status === 'PENDING') &&
       <ChangePrice assignmentId={record.id} />}

    {UserService.hasRoles(['ROLE_PLATFORM_MANAGER', 'ROLE_GATED_COMMUNITY_MANAGER']) &&
      record.reminderTicketId &&
      <ReminderTicket ticketId={record.reminderTicketId} />}
  </Box>
}

export default React.memo(AssignmentShowActions)