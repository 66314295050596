import React from 'react'
import { Datagrid, ReferenceManyField, NumberField, RaRecord } from 'react-admin'
import Box from '@mui/material/Box'

import UserService from 'UserService'
import { CreatePricingRuleModal } from './companyModals/CreatePricing'
import { EditPricingRuleModal } from './companyModals/EditPricing'
import ActionButton from 'components/ActionButton'

import 'styles.scss'
import { Chipify } from 'common/Chipify'
import { EditIconSmall } from 'common/EditField'
import { handleEsc } from 'common/closeOnEscape'

interface Props {
  type: 'single' | 'multi'
  basePath?: string
  record: RaRecord
}

const PricingRules = ({ basePath, type, record }: Props) => {
  type OpenModal = {
    type: 'EDIT' | 'CREATE',
    record: RaRecord,
  } | undefined
  const [open, setOpen] = React.useState<OpenModal>(undefined)

  const close = () => { setOpen(undefined) }

  React.useEffect(handleEsc(close), [])

  const editPricing = open?.type==="EDIT" && 
  <EditPricingRuleModal 
    record={open.record} 
    open={open.type === 'EDIT'} 
    onClose={close} 
  />
  
  const createPricing = open?.type==="CREATE" && 
  <CreatePricingRuleModal 
    record={open.record} 
    open={open.type === 'CREATE'} 
    onClose={close} 
  />

  const createBoundle = open?.type==="CREATE" && 
  <CreatePricingRuleModal 
    record={open.record} 
    open={open.type === 'CREATE'} 
    onClose={close} 
    useMulti 
    warning="Bundle price is applied only when it is lower than the sum of individual stages." 
    displayCurrentPrice
  />
  const editBoundle = open?.type==="EDIT" && 
  <EditPricingRuleModal 
    record={open.record}
    open={open.type === 'EDIT'} 
    onClose={close} 
    useMulti
  />

  const edit = type === 'single' ? editPricing : editBoundle
  const create = type === 'single' ? createPricing : createBoundle

  const noPricesMessage = type === 'single' ? 'No stage prices overrides' : 'No bundle prices set'

  return <>
    {edit}
    {create}
    
    <Box>
      <Box className='edit-tab'>
        <Box className='on-right'>
          {UserService.hasRoles(['ROLE_PLATFORM_MANAGER']) && <ActionButton onClick={() => { setOpen({ type: "CREATE", record: record })}}>Create</ActionButton>}
        </Box>
      </Box>
      <ReferenceManyField
        reference={`pricingrules/${type}`}
        target="companyId"
        sort={{ field: 'name', order: 'DESC' }}
        perPage={50}
        fullWidth
      >
        <Datagrid 
          resource={`pricingrules/${type}`} 
          bulkActionButtons={false} 
          className='assigments-datagrid' 
          empty={<>{noPricesMessage}</>}
        >
          <Chipify source='stages' translation="stage.type." label={'Stage'} sortable={false} variant='squere-chip' />
          <NumberField
            source='price'
            label='Price Per Word'
            sortable={false}
            options={{ style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 15 }} />
          {UserService.hasRoles(['ROLE_PLATFORM_MANAGER']) && !/single/.test(basePath ?? '') && <EditIconSmall onClick={(e) => {
            setOpen({
              type: 'EDIT',
              record: e
            })
          }} />}
        </Datagrid>
      </ReferenceManyField>
    </Box>
  </>
}

export default PricingRules