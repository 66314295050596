import { Modal, Box, InputAdornment, IconButton } from "@mui/material"
import { WhiteTextField } from "common/TextField"
import { RaRecord, useNotify, useDataProvider, useRefresh } from "ra-core"
import React, { useState } from "react"
import { ModalProps } from "./common"
import { Chipify } from "common/Chipify"
import RoundedPanel, { Alert } from "common/RoundedPanel"
import { SumbitButtons } from "common/SubmitButtons"
import CloseIcon from '@mui/icons-material/Close'
import { PricingRule } from "api/common"

export const EditPricingRuleModal = ({ open, onClose, record, useMulti }: ModalProps<RaRecord> & { useMulti?: boolean }) => {
  const { id, price, companyId, stages } = record

  const [pricePerWord, setPricePerWord] = useState<number>(price)
  const [deleteState, setDeleteState] = useState<"NONE" | "WARN">("NONE")

  const notify = useNotify()
  const refresh = useRefresh()
  const dataProvider = useDataProvider()

  const pricingRulePath = useMulti ? 'pricingrules/multi' : 'pricingrules/single'

  const applyChanges = () => {
    const data: PricingRule = {
      id: id as string,
      price: pricePerWord,
      companyId: companyId,
      stages: stages
    }

    dataProvider
      .update(pricingRulePath, { id: id, data: data, previousData: record })
      .then(() => {
        notify('Price rule was successfully updated.', { type: 'success' })
        refresh()
        onClose()
      })
      .catch((error) => {
        refresh()
        notify(error?.message ?? 'Something went wrong.', { type: 'error' })
      })
  }

  const onDelete = () => {
    if (deleteState == "NONE") {
      setDeleteState("WARN")
    }
    if (deleteState == "WARN") {
      dataProvider
        .delete(pricingRulePath, { id: id, previousData: record })
        .then(() => {
          notify('Price rule was successfully deleted.', { type: 'success' })
          onClose()
        })
        .catch((error) => {
          notify(error?.message ?? 'Something went wrong.', { type: 'error' })
        })
        .finally(() => {
          refresh()
        })
    }
  }

  const deleteWarn = deleteState == "NONE" ? <Box height={32} /> : <Box margin="20px 0px">
    <Alert
      variant="warn"
    >
      Are you sure you wanto to delete this item?
    </Alert>
  </Box>

  return <Modal
    open={open}
    onClose={onClose}
    disableAutoFocus={true}
  >
    <RoundedPanel modal width="400px">
      <IconButton onClick={onClose} className="close-button">
        <CloseIcon />
      </IconButton>
      <form
        onSubmit={applyChanges}
      >
        <h1>Stage: <Chipify source={stages} translation="stage.type." variant="squere-chip" /></h1>
        <WhiteTextField
          id="pricePerWord"
          label="Price per word"
          fullWidth
          onChange={(e) => { setPricePerWord(parseFloat(e.target.value)) }}
          defaultValue={price}
          type='number'
          InputProps={{
            endAdornment: <InputAdornment position="start">€</InputAdornment>
          }}
          inputProps={{
            step: 0.0001,
            min: 0,
            max: 99
          }}
        />
        {deleteWarn}
        <SumbitButtons
          onClose={onDelete}
          onCloseLabel="Delete"
        />
      </form>
    </RoundedPanel>
  </Modal>
}
