import { Box } from '@mui/material'
import React from 'react'
import { Button } from 'react-admin'

import 'styles.scss'

interface SubmitButtonsProps {
  onClose?: () => void
  onSave?: () => void
  onDelete?: () => void
  onCloseLabel?: string
  onSaveLabel?: string
  onDeleteLabel?: string
  isLoading?: boolean
  saveDisabled?: boolean
}

export const SumbitButtons = ({ onClose, onSave, onDelete, onCloseLabel, onSaveLabel, onDeleteLabel, isLoading, saveDisabled }: SubmitButtonsProps) => {
  return (
    <Box width={'auto'}>
      <Button
        variant="contained"
        className='outlined-button'
        color="primary"
        label={ onSaveLabel || 'Save' }
        type="submit"
        disabled={ isLoading || saveDisabled }
        onClick={onSave}
        sx={{
          marginRight: '10px',
          width: '70px',
          height: '33px'
        }}
      />
      <Button
        variant="contained"
        className='outlined-button'
        color="secondary"
        label={ onCloseLabel || 'Discard' }
        disabled={isLoading}
        onClick={onClose}
        sx={{
          width: '70px',
          height: '33px'
        }}
      />
      {onDelete && 
      <Button
        variant="contained"
        className='outlined-button'
        color="secondary"
        label={ onDeleteLabel || 'Delete' }
        disabled={isLoading}
        onClick={onDelete}
        sx={{
          marginLeft: '10px',
          width: '70px',
          height: '33px'
        }}
      />}
    </Box>
  )
}