import React, { useCallback } from 'react'
import { Datagrid, FieldProps, FunctionField, List, ListContextProvider, NumberField, NumberInput, ReferenceManyField, SelectArrayInput, SelectInput, 
  TextField, useGetList, useListContext, useRecordContext, useTranslate, Link } from 'react-admin'
import moment from 'moment'

import Divider from '@mui/material/Divider'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

import ChangeRequesterActionButton from './ChangeRequesterActionButton'
import assignmentRowStyle from 'assignment/rowStyle'
import stageRowStyle from 'stage/rowStyle'
import UserService from 'UserService'
import CurrentStageField from './CurrentStageField'
import { getOptionsSelectFromArray } from 'utils/helpers'
import { AdminJobDashboardRecord } from 'api/jobs'
import { jobTypes, stages } from 'common/dictonary'
import CompanySearchFilter from './CompanySearchFilter'
import { CustomDateField, CustomDateInput, CustomDeadlineField, CustomLinkField, CustomPagination, CustomSecondsToReadabletimeField,
  GatedCommunitySearchFilter, LanguageFilter, CustomTranslatedTextField, CustomShowInEditorButton } from 'common'
import ListToolbar from './ListToolbar'
import { AssignmentJobSection } from 'api/assignments'
import CustomTextField from 'common/CustomTextField'
import { OutlinedSearchInput } from 'common/OutlinedSearchInput'
import { OutlinedSelectInput } from 'common/OutlinedSelectInput'
import { IconButton, Tooltip } from '@mui/material'
import MailIcon from '@mui/icons-material/Mail'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import { RCASmallInfo } from 'api/rca'

const orderFilters = [
  <OutlinedSearchInput source="q" alwaysOn label="Search"/>,
  <GatedCommunitySearchFilter alwaysOn source='gatedCommunities' />,
  <OutlinedSelectInput source="type" choices={getOptionsSelectFromArray(jobTypes, 'job.type.')} alwaysOn label="Type" />,
  <LanguageFilter alwaysOn source='sourceLang' label='Source Lang' />,
  <LanguageFilter alwaysOn source='targetLang' label='Target Lang' />,
  <CompanySearchFilter alwaysOn source='companyId' />,
  <CustomDateInput source="up_date_s" label="Created Start" />,
  <CustomDateInput source="up_date_e" label="Created End" />,
  <CustomDateInput source="du_date_s" label="Due Date Start" enableFuture />,
  <CustomDateInput source="du_date_e" label="Due Date End" enableFuture />,
  <CustomDateInput source="de_date_s" label="Delivered Start" />,
  <CustomDateInput source="de_date_e" label="Delivered End" />,
  <SelectArrayInput source="rcaTypes" label='RCA' choices={[{ id: 'INTERNAL_RCA', name: 'INTERNAL RCA' }, { id: 'REQUESTER_RCA', name: 'REQUESTER RCA' }]} />,
  <SelectInput source="containsStage" label="Contains Stage" choices={getOptionsSelectFromArray(stages, 'stage.type.')} resettable sx={{ width: '220px' }} />,
  <SelectInput source="currentStage" label="Current Stage" choices={getOptionsSelectFromArray(stages, 'stage.type.')} resettable sx={{ width: '220px' }} />,
  <NumberInput source="assignmentScoreStart" label={'Minimum Exfluenscore'} min={0} max={10} />,
  <NumberInput source="assignmentScoreEnd" label={'Maximum Exfluenscore'} min={0} max={10} />
]

const tabs = [
  { id: 'IN_PROGRESS', name: 'In Progress' },
  { id: 'BLOCKED', name: 'Blocked' },
  { id: 'FINISHED', name: 'Finished' },
  { id: 'CANCELLED', name: 'Cancelled' },
  { id: 'DELETED', name: 'Deleted' }
]

const useGetTotals = (filterValues: any) => {
  const { total: totalOrdered } = useGetList('jobs', {
    pagination: { perPage: 1, page: 1 },
    sort: { field: 'uploadDate', order: 'ASC' },
    filter: { ...filterValues, status: 'IN_PROGRESS' }
  })
  const { total: totalDelivered } = useGetList('jobs', {
    pagination: { perPage: 1, page: 1 },
    sort: { field: 'uploadDate', order: 'ASC' },
    filter: { ...filterValues, status: 'FINISHED' }
  })
  const { total: totalCancelled } = useGetList('jobs', {
    pagination: { perPage: 1, page: 1 },
    sort:{ field: 'uploadDate', order: 'ASC' },
    filter: { ...filterValues, status: 'CANCELLED' }
  })
  const { total: totalBlocked } = useGetList('jobs', {
    pagination: { perPage: 1, page: 1 },
    sort: { field: 'uploadDate', order: 'ASC' },
    filter: { ...filterValues, status: 'BLOCKED' }
  })
  const { total: totalDeleted } = useGetList('jobs', {
    pagination: { perPage: 1, page: 1 },
    sort:{ field: 'uploadDate', order: 'ASC' },
    filter: { ...filterValues, status: 'DELETED' }
  })

  return {
    IN_PROGRESS: totalOrdered,
    FINISHED: totalDelivered,
    CANCELLED: totalCancelled,
    BLOCKED: totalBlocked,
    DELETED: totalDeleted
  }
}

const JobExpand = () => {
  const translate = useTranslate()

  return <ReferenceManyField
    reference="stages"
    target="jobId"
    sort={{ field: 'order', order: 'DESC' }}
    perPage={50}
    fullWidth
  >
    <Datagrid rowClick='expand' expand={<StageExpand />} rowStyle={row => stageRowStyle(row as any)} isRowExpandable={row => !!row.assignments.length}
      bulkActionButtons={false}>
      <CustomLinkField source="type" label={'Type'} translationPath='stage.type.' sortable={false} basePath='stages' show targetFieldId='id' />
      <CustomTranslatedTextField source="status" translationPath='stage.status.' sortable={false} />
      <FunctionField source='sum' label={'Selection Method'} sortable={false}
        render={(row: any) => translate(`stage.selectionRow.${row?.parameters?.ExecutorSelection || 'auto'}`)} />
      <CustomDateField source="dueDate" label={'Due Date'} sortable={false} />
    </Datagrid>
  </ReferenceManyField>
}

const StageExpand = () => {
  return <ReferenceManyField
    reference="assignments"
    target="stageId"
    sort={{ field: 'dueDate', order: 'DESC' }}
    perPage={50}
    fullWidth
  >
    <Datagrid expand={<SectionsExpand />} rowStyle={row => assignmentRowStyle(row as any)} rowClick={(id, basePath, record) => `/assignments/${record.id}/show`} bulkActionButtons={false}
      isRowSelectable={() => true}>
      <TextField source="executor" label="Exfluencer" sortable={false} />
      <CustomTranslatedTextField source="status" label='Status' translationPath='assignment.status.' sortable={false} />
      <NumberField source="progress" options={{ style: 'percent' }} sortable={false} />
      <NumberField source="trustChainScore" label={'Exfluenscore'} options={{ maximumFractionDigits: 1, minimumFractionDigits: 1 }} sortable={false} />
      <CustomDeadlineField source="dueDate" deliveryDate label="Deadline" sortable={false} />
      <CustomDateField source="dueDate" label="Due Date" sortable={false} />
      <CustomSecondsToReadabletimeField source="workedSeconds" label="Worked Time" textAlign='right' sortable={false} />
      <CustomSecondsToReadabletimeField source="estimatedSeconds" label="Estimated Time" textAlign='right' sortable={false} />
      <NumberField source="price" options={{ style: 'currency', currency: 'EUR' }} sortable={false} />
    </Datagrid>
  </ReferenceManyField>
}

const SectionsExpand = () => {
  const record = useRecordContext<AssignmentJobSection>()
  if(!record ) return null

  return <List
    resource={`assignments/${record.id}/jobSections`}
    exporter={false}
    disableSyncWithLocation
    title={' '}
    actions={false}
    perPage={10}
    empty={<div>No sections</div>}
    pagination={<CustomPagination />}
  >
    <Datagrid bulkActionButtons={false} sx={{ '& .RaDatagrid-headerCell': { 'background': '#f2f2f2' } }}>
      <TextField source="name" label="File name" sortable={false} />
      <TextField source="totalAssets" label="Total Assets" sortable={false} />
      <TextField source="completedAssets" label="Completed Assets" sortable={false} />
    </Datagrid>
  </List>
}

const jobRowStyle = (record: AdminJobDashboardRecord) => {
  let backgroundColor = 'white'
  if(record.status === 'IN_PROGRESS') {
    if(record.soonToBeLate && 
    UserService.hasRoles(['ROLE_GATED_COMMUNITY_MANAGER', 'ROLE_EXFLUENCY_ADMIN', 'ROLE_PLATFORM_MANAGER'])){
      backgroundColor = 'rgba(255, 255, 0, 0.1)'
    }
    if((moment().isAfter(record.dueDate) || record.overdue) && 
    UserService.hasRoles(['ROLE_GATED_COMMUNITY_MANAGER', 'ROLE_EXFLUENCY_ADMIN', 'ROLE_PLATFORM_MANAGER'])){
      backgroundColor = 'rgba(255, 100, 100, 0.1)'
    }
  }else{
    if((record.deliveryDate && moment(record.deliveryDate).isAfter(record.dueDate)) || 
    (!record.deliveryDate && moment().isAfter(record.dueDate))) {
      backgroundColor = '#fffbe9'
    }
  }
  return { backgroundColor }
}

const TabbedDatagrid = () => {
  const listContext = useListContext()
  const { filterValues, setFilters, displayedFilters } = listContext
  const totals = useGetTotals(filterValues) as any

  const handleChange = useCallback(
    (event: React.ChangeEvent<any>, value: any) => {
      setFilters &&
        setFilters(
          { ...filterValues, status: value },
          displayedFilters
        )
    },
    [displayedFilters, filterValues, setFilters]
  )

  const isRole_SUPPORT_GC_MENAGER_PLATFORM_MANAGER = UserService.hasRoles(['ROLE_SUPPORT', 'ROLE_GATED_COMMUNITY_MANAGER', 'ROLE_PLATFORM_MANAGER'])
  const isRole_SUPPORT_EXFLUENCY_ADMIN = UserService.hasRoles(['ROLE_SUPPORT', 'ROLE_EXFLUENCY_ADMIN'])

  return <>
    <Tabs
      variant="fullWidth"
      centered
      value={filterValues.status}
      indicatorColor="primary"
      onChange={handleChange}
    >
      {tabs.map(choice => (
        <Tab
          key={choice.id}
          label={
            totals[choice.id] ?
                `${choice.name} (${totals[choice.id]})` :
                choice.name
          }
          value={choice.id}
        />
      ))}
    </Tabs>
    <Divider />
    <div>
      <ListContextProvider value={{ ...listContext }}>
        <Datagrid rowClick="expand" expand={<JobExpand />} rowStyle={jobRowStyle} sx={{ '.MuiTableCell-root': { position: 'relative' } }} 
          bulkActionButtons={isRole_SUPPORT_GC_MENAGER_PLATFORM_MANAGER && <ChangeRequesterActionButton />}>
          <CustomLinkField source="requester" label={'Requester'} sortable={false} basePath='users' show targetFieldId='requesterId' />
          <CustomTranslatedTextField source="externalProjectId" label={'External ID'} sortable={false} trim />
          <CustomLinkField source="name" targetFieldId="id" basePath='jobs' show label={'Project Name'} trim />
          <CustomShowInEditorButton source="lastAccessibleAssignmentId" label={''}/>
          <ZendeskReminderTicketButton source="reminderTicketId" label={''}/>
          <ShowRCAsButton source="rcas" label={''}/>
          <CustomTranslatedTextField source="sourceLang" label={'Source'} />
          <CustomTranslatedTextField source="targetLang" label={'Target'} />
          <CustomTextField source={(record) => record.jobSections.length} label={'Files'} />
          <CustomDateField source="uploadDate" label={'Created'} />
          {['IN_PROGRESS', 'BLOCKED'].includes(filterValues.status) && <CustomDateField source="dueDate" label={'Due Date'} />}
          {['IN_PROGRESS', 'BLOCKED'].includes(filterValues.status) && <CurrentStageField source='currentStage' sortable={false} label={'Current Stage'} />}
          {filterValues.status == 'FINISHED' && <CustomDateField source="deliveryDate" label={'Delivered'} />}
          {isRole_SUPPORT_EXFLUENCY_ADMIN&&<NumberField source="exfluencyFee" label={'Fee'} options={{ style: 'currency', currency: 'EUR' }} />}
          <NumberField source="cost" label={'Total'} sortable={false} options={{ style: 'currency', currency: 'EUR' }} />
        </Datagrid>
      </ListContextProvider>
    </div>
  </>
}

const ZendeskReminderTicketButton = ({ source }: FieldProps) => {
  const record = useRecordContext()

  return (UserService.hasRoles(['ROLE_PLATFORM_MANAGER', 'ROLE_GATED_COMMUNITY_MANAGER']) && record && source && record[source]) ?
    <a href={"https://exfluency.zendesk.com/agent/tickets/" + record[source]} target="_blank" rel="noreferrer">
      <IconButton aria-label='open zendesk ticket'>
        <MailIcon />
      </IconButton>
    </a> :
    null
}

const rcaButtonBackgroundColor = (rcas: RCASmallInfo[]): string => {
  if(rcas.length === 0) {
    return ''
  }
  let rca = rcas.find(rca => rca.rcaType === "REQUESTER_RCA")
  if(rca === undefined){
    [ rca ] = rcas
  }
  if(rca?.rcaStatus === 'COMPLETED' || rca?.rcaStatus === 'APPROVED') {
    return '#A8DDC2'
  }
  if(rca?.rcaStatus ==='IN_PROGRESS') {
    return '#84D0FD'
  }
  if(rca?.rcaStatus === 'REQUESTED') {
    return '#F6D887'
  }
  if(rca?.rcaStatus ==='REJECTED') {
    return '#FF9296'
  }
  return ''
}

const ShowRCAsButton = ({ source }: FieldProps) => {
  const record = useRecordContext()
  
  return ( record && source && record[source] && record[source].length !== 0) ? 
  <Link to={`/jobs/${record.id}/rcas`}>
    <IconButton aria-label='open rca page' sx={{ backgroundColor: rcaButtonBackgroundColor(record[source] as RCASmallInfo[]) }}>
      <Tooltip id="button-rca-page" title="Open RCA page">
        <ContentPasteIcon />
      </Tooltip>
    </IconButton>
  </Link> :
   null
}

const OrderList = () => <List
  filterDefaultValues={{ status: 'IN_PROGRESS', type: "COMMERCIAL" }}
  pagination={<CustomPagination />}
  sort={{ field: 'uploadDate', order: 'DESC' }}
  perPage={50}
  filters={orderFilters}
  actions={<ListToolbar />}
>
  <TabbedDatagrid />
</List>

export default OrderList
