import React from 'react'
import { useRecordContext, useTranslate, Show, Link } from 'react-admin'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { AdminStageDashboardRecord, getLogs } from 'api/stages'
import { parseTimestamp } from 'utils/helpers'
import UserService from 'UserService'
import { Divider } from '@mui/material'
import Status from 'components/Status'
import SideCard from 'components/SideCard'
import { NormText, CardHeader, GAP, CARD_WIDTH, NoSel, STANDARD_PADDING, SM_FONT_LINE_H28 } from 'job/jobShow/Common'
import { Logs } from 'job/actions'
import { sortBy } from 'lodash'
import { wrap } from 'module'

const StageShow = () => {
  return <Show title={<StageTitle />} component="div" sx={{ bgcolor: '#FAFAFA', paddingLeft: '4%', paddingRight: '1%', height: '100%', '.RaShow-main': { marginTop: '71px' } }}>
    <StageShowInfo />
  </Show>
}

const StageAssignments = () => {
  const translate = useTranslate()
  const record = useRecordContext<AdminStageDashboardRecord>()
  const sortedAssignments = sortBy(record?.assignments, ['uploadDate', 'id'])
  return <SideCard width={CARD_WIDTH}>
    <Box padding='25px 25px 25px 35px' paddingRight='35px' paddingBottom='20px' display='flex' gap='10px' justifyContent='space-between'>
      <NormText sx={{ width: '44%', font: SM_FONT_LINE_H28 }}><b>Assignment</b></NormText>
      <NormText sx={{ width: '26%', font: SM_FONT_LINE_H28 }}><b>Status</b></NormText>
      <NormText sx={{ width: '30%', font: SM_FONT_LINE_H28 }}><b>Contributor</b></NormText>
    </Box>

    <Divider />

    <Box padding='25px 25px 25px 35px' paddingRight='35px' paddingBottom='20px' display='flex' gap='10px' justifyContent='space-between'>
      <Box display='flex' flexDirection='column' gap='31px' width='40%' word-wrap='break-word'>   
        {sortedAssignments.map(( { id } ) => (   
          <NormText style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }} key={id}><Link to={`/assignments/${id}/show`}>{id}</Link></NormText>
        ))}
      </Box>

      <Box display='flex' flexDirection='column' gap='31px' width='30%'>
        {sortedAssignments.map(( { status } ) => (   
          <Status status={status} text={translate(`assignment.status.${status}`)} />
        ))}    
      </Box>
        
      <Box display='flex' flexDirection='column' gap='31px' width='30%'>
        {sortedAssignments.map(( { executorUsername, executorId } ) => (   
          <NormText><Link to={`/users/${executorId}/show`}>{executorUsername}</Link></NormText>
        ))}
      </Box>
    </Box>
  </SideCard>
}

const StageInfo = () => {
  const record = useRecordContext<AdminStageDashboardRecord>()
  const translate = useTranslate()

  if(!record) return null

  return <Box display='flex' gap={GAP}>
    <SideCard width={CARD_WIDTH}>
      <Box padding='33px 2px 21px 54px' display='flex' alignItems='center' gap='18px'>
        <CardHeader>
          <b>{translate(`stage.type.${record?.type}`)}</b>
        </CardHeader>
        <Status text={translate(`stage.selection.${record?.parameters?.ExecutorSelection || 'auto'}`)} />
      </Box>
      <Divider />
      <Box padding='47px 2px 30px 54px' display='flex' flexDirection='column' gap='15px'>
        <NormText>
          <NoSel><b>Job Id: </b></NoSel><Link to={`/jobs/${record?.jobId}/show`}>{record?.jobId}</Link>
        </NormText>
        <NormText>
          <NoSel><b>Id: </b></NoSel>{record?.id}
        </NormText>
      </Box>
    </SideCard>
  </Box>
}

const StageDate = () => {
  const record = useRecordContext<AdminStageDashboardRecord>()
  const translate = useTranslate()
  const { timezone } = UserService.getTokenUserInfo()

  return <SideCard width={CARD_WIDTH}>
    <Box padding='30px 26px 26px 38px' display='flex' alignItems='center' gap='18px'>
      <CardHeader>
        <b>Status: </b>
      </CardHeader>
      <Status status={record?.status} text={translate(`stage.status.${record?.status}`)} />
    </Box>

    <Divider />

    <Box padding='37px 2px 47px 38px'>
      <NormText><b>Due Date: </b>
        {parseTimestamp(record?.dueDate, timezone)}
      </NormText>
    </Box>
  </SideCard>
}
const StageActions = () => {
  const record = useRecordContext<AdminStageDashboardRecord>()

  if (!record) return null

  return <Box display='flex' gap="10px" flexWrap='wrap'>
    {UserService.hasRoles(['ROLE_EXFLUENCY_ADMIN', 'ROLE_SUPPORT']) && <Logs getLogsPromise={() => getLogs(record.id)} />}
  </Box>
}

const StageTitle = () => {
  const record = useRecordContext<AdminStageDashboardRecord>()
  return <span>{record?.id}</span>
}

const StageShowInfo = () => {
  return (
    <Box display={{ md: 'block', lg: 'flex' }} gap="35px" flexWrap='wrap'>
      <Typography sx={{ font: 'normal normal bold 26px/31px Inter' }}>
        Stage Information
      </Typography>
      <StageActions />
      <Box display='flex' gap={GAP} flexWrap='wrap' alignItems='flex-start'>
        <Box display='flex' flexDirection='column' gap="25px" flexWrap='wrap' alignItems='flex-start'>
          <StageInfo />
          <StageAssignments />
        </Box>
        <Box display='flex' flexDirection='column' gap="25px" flexWrap='wrap' alignItems='flex-start'>
          <StageDate />
        </Box>
      </Box>
    </Box>
  )
}

export default StageShow