import React from 'react'
import axios from 'axios'
import { CustomRoutes, Resource } from 'react-admin'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'

import Providers from 'providers/Providers'
import UserService from 'UserService'

import users from 'user'
import invoices from 'invoice'
import featureSwitches from 'featureSwitch'
import featureParameters from 'featureParameter'
import pricingRules from 'pricingRule'
import companies from 'company'
import gatedCommunities from 'gatedCommunity'
import jobs from 'job'
import stages from 'stage'
import assignments from 'assignment'
import assets from 'assets'
import { Route } from 'react-router-dom'
import RCAList from 'job/RCAList'
import SubAssets from 'assets/SubAssets'

const contentType = 'Content-Type'
const header = 'application/json'

axios.defaults.headers.post[contentType] = header
axios.defaults.headers.get[contentType] = header
axios.defaults.headers.put[contentType] = header
axios.interceptors.request.use(config =>
  UserService.unauthenticatedPath() ? Promise.resolve(config) :

    new Promise((resolve) => UserService.updateToken(async function () {
      const token = await UserService.getToken()
      if (token && config && config.headers) config.headers.Authorization = `Bearer ${token}`
      resolve(config)
    }))
)

const App = () => {
  return <Providers>
    <Resource name='jobs' {...jobs} options={{ label: 'Projects' }} />
    <Resource name='users' {...users} />
    {UserService.hasRoles(['ROLE_PLATFORM_MANAGER', 'ROLE_SUPPORT', 'ROLE_COMMUNITY_MANAGER', 'ROLE_GATED_COMMUNITY_MANAGER']) && <Resource name='companies' {...companies} />}
    <Resource name='gatedCommunities' {...gatedCommunities} options={{ label: 'Gated Communities' }} />
    <Resource name='stages' {...stages} />
    <Resource name='assignments' {...assignments} />
    {UserService.hasRoles(['ROLE_PLATFORM_MANAGER', 'ROLE_SUPPORT', 'ROLE_COMMUNITY_MANAGER', 'ROLE_GATED_COMMUNITY_MANAGER']) && <CustomRoutes>
      <Route path="jobs/:jobId/rcas" element={<RCAList />} />
    </CustomRoutes>}
    <Resource name='skills' />
    {UserService.hasRoles(['ROLE_PLATFORM_MANAGER', 'ROLE_SUPPORT', 'ROLE_COMMUNITY_MANAGER']) && <Resource name='invoices' {...invoices} />}
    {UserService.hasRoles(['ROLE_PLATFORM_MANAGER']) && <Resource name='pricingrules/single' {...pricingRules } options={{ label: 'Stage Prices' }} />}
    {UserService.hasRoles(['ROLE_PLATFORM_MANAGER']) && <Resource name='pricingrules/multi' {...pricingRules} icon={MonetizationOnIcon} options={{ label: 'Bundle Prices' }} />}
    {UserService.hasRoles([]) && <Resource name='features/switches' {...featureSwitches} options={{ label: 'Feature Switches' }} />}
    {UserService.hasRoles([]) && <Resource name='features/parameters' {...featureParameters} options={{ label: 'Feature Parameters' }} />}
    {UserService.hasRoles(['ROLE_PLATFORM_MANAGER']) && <Resource name='assets' {...assets} options={{ label: 'Language Miner' }} />}
    {UserService.hasRoles(['ROLE_PLATFORM_MANAGER']) && <CustomRoutes>
      <Route path="subassets" element={<SubAssets />} />
    </CustomRoutes>}
  </Providers>
}

export default App
