import React from "react"
import { RaRecord, useRecordContext } from "ra-core"
import { EditIcon } from "./Icons"

interface EditIconSmallProps {
  onClick: (id: RaRecord) => void
}

export const EditIconSmall = ({ onClick }: EditIconSmallProps) => {
  const record = useRecordContext()

  return <EditIcon onClick={() => onClick(record)} />
}
