import React from 'react'
import { Datagrid, NumberField, List, useRecordContext, TextField, useTranslate, FieldProps } from 'react-admin'

import CustomDeadlineField from 'common/CustomDeadlineField'
import CustomSecondsToReadabletimeField from 'common/CustomSecondsToReadabletimeField'
import CustomDateField from 'common/CustomDateField'
import { getOptionsSelectFromArray } from 'utils/helpers'
import { ASSIGNMENT_STATUS_LIST, StageType } from 'api/common'
import CustomTranslatedTextField from 'common/CustomTranslatedTextField'
import CustomPagination from 'common/CustomPagination'
import { AssignmentJobSection } from 'api/assignments'
import Status from 'components/Status'
import { OutlinedArrayInput } from 'common/OutlinedArrayInput'

import 'styles.scss'
import { FilterExportToolbar } from 'common/FilertExportToolbar'
import { UserRecord } from 'api/users'

const STAGES: StageType[] = ['ENHANCEMENT', 'TRUST_MINING', 'REDACTION', 'FINAL_REDACTION', 'CORRECTION']

const orderFilters = [
  <OutlinedArrayInput source="types" choices={getOptionsSelectFromArray(STAGES, 'stage.type.')} label="Types" alwaysOn />,
  <OutlinedArrayInput source="status" choices={getOptionsSelectFromArray(ASSIGNMENT_STATUS_LIST, 'assignment.status.')} label="Status" alwaysOn />
]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StatusField = (_: FieldProps) => {
  const record = useRecordContext()
  const translate = useTranslate()

  return record ? <div>
    <Status status={record.status} text={translate(`assignment.status.${record.status}`)} />
  </div> : null
}

const Assignments = () => {
  return <>
    <Datagrid
      className='assigments-datagrid'
      expand={<SectionsExpand />}
      resource='assignments'
      rowClick={(id, basePath, record) => `/assignments/${record.id}/show`}
      bulkActionButtons={false}>
      <CustomTranslatedTextField source="type" label="Type" translationPath='stage.type.' />
      <StatusField source="status" label="Status" />
      <CustomTranslatedTextField source="sourceLanguage" label="Source" sortable={false} />
      <CustomTranslatedTextField source="targetLanguage" label="Target" sortable={false} />
      <NumberField source="progress" options={{ style: 'percent' }} />
      <NumberField source="trustChainScore" label={'Exfluenscore'} sortable={false} />
      <CustomDeadlineField source="dueDate" deliveryDate label="Deadline" />
      <CustomDateField source="dueDate" label="Due Date" />
      <CustomDateField source="deliveryDate" label="Delivery Date" />
      <NumberField source="price" options={{ style: 'currency', currency: 'EUR' }} label="Price" textAlign="left" />
      <CustomSecondsToReadabletimeField source="workedSeconds" sortBy='workedTime' textAlign="right" label="Worked Time" />
      <CustomSecondsToReadabletimeField source="estimatedSeconds" sortBy='estimatedTime' textAlign="right" label="Estimated Time" />
    </Datagrid>
  </>
}

const SectionsExpand = () => {
  const record = useRecordContext<AssignmentJobSection>()
  if (!record) return null

  return <List
    resource={`assignments/${record.id}/jobSections`}
    exporter={false}
    disableSyncWithLocation
    title={' '}
    actions={false}
    perPage={10}
    pagination={<CustomPagination />}
  >
    <Datagrid bulkActionButtons={false}>
      <TextField source="name" label="File name" sortable={false} />
      <TextField source="totalAssets" label="Total Assets" sortable={false} />
      <TextField source="completedAssets" label="Completed Assets" sortable={false} />
    </Datagrid>
  </List>
}

const AssignmentsList = () => {
  const record = useRecordContext<UserRecord>()

  return <List
    resource='assignments'
    title={'/assignments'}
    filter={{ userId: record.id }}
    actions={<FilterExportToolbar filters={orderFilters} showFilter showExport />}
    sort={{ field: 'dueDate', order: 'DESC' }}
    perPage={50}
    pagination={<CustomPagination />}
  >
    <Assignments />
  </List>
}

export default AssignmentsList