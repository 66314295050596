import { CustomDateField, GatedCommunitySearchFilter, LanguageFilter } from 'common'
import React from 'react'
import { Datagrid, List, SelectArrayInput, SelectInput, TextField, TextInput, useListContext } from 'react-admin'

const SINGLE_PAGE_LIMIT = 100

const assetsFilters = [
  <LanguageFilter label="Source lang" source="sourceLang" alwaysOn />,
  <LanguageFilter label="Target lang" source="targetLang" alwaysOn />,
  <TextInput label="Text" source="text" alwaysOn />,
  <SelectInput source="searchType" choices={[
    { id: 'SOURCE_EXACT', name: 'Source text exact match' },
    { id: 'SOURCE_FULL', name: 'Source full text search' },
    { id: 'TARGET_EXACT', name: 'Target text exact match' },
    { id: 'TARGET_FULL', name: 'Target full text search' },
    { id: 'ANY_EXACT', name: 'Any text exact match' },
    { id: 'ANY_FULL', name: 'Any full text search' }
  ]} alwaysOn />,
  <GatedCommunitySearchFilter alwaysOn source='gatedCommunities' />,
  <SelectArrayInput source="translationLevels" choices={[
    { id: 'PARSED', name: 'Parsed' },
    { id: 'VERIFIED', name: 'Verified' }
  ]} alwaysOn />
]

const ListSizeFooter = () => {
  const { data } = useListContext()
  return <div>Total: {data?.length} ({SINGLE_PAGE_LIMIT} is the current max (no pagination yet))</div>
}

const AssetsList = () => {

  return <List actions={false} perPage={SINGLE_PAGE_LIMIT} pagination={<ListSizeFooter />} filters={assetsFilters} filterDefaultValues={{ searchType: 'SOURCE_FULL' }}>
    <Datagrid >
      <TextField source='sourceLang' label={'Source lang'} sortable={false} />
      <TextField source='targetLang' label={'Target lang'} sortable={false} />
      <TextField source='sourceText' label={'Source text'} sortable={false} />
      <TextField source='targetText' label={'Target text'} sortable={false} />
      <CustomDateField source='creationDate' label={'Creation date'} sortable={false} />
    </Datagrid>
  </List>
}

export default React.memo(AssetsList)