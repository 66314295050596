import { Radio } from '@mui/material'
import { useRecordContext } from 'ra-core'
import React from 'react'

interface RadioFieldProps {
    selected: string,
    label: string,
    setSelected: (id: string) => void,
    size?: 'small'|'medium'
}

export const RadioField = ({ selected, setSelected, size }: RadioFieldProps) => {
  const record = useRecordContext()

  return <Radio
    size={size}
    checked={selected === record.id}
    onChange={() => setSelected(record.id as string)}
  />
}

