import React, { useState } from 'react'
import { useNotify, useRefresh } from 'react-admin'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'

import { changeAssignmentPrice } from 'api/assignments'
import CustomButton from 'components/CustomButton'

interface Props {
  assignmentId: string
}

const ChangePrice = ({ assignmentId }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [newPrice, setNewPrice] = useState<number | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const notify = useNotify()
  const refresh = useRefresh()

  const handleInterruptAssignment = async () => {
    if(newPrice === undefined || newPrice < 0) return

    try {
      setIsLoading(true)
      await changeAssignmentPrice(assignmentId, newPrice)
      notify('Successfully changed assignment price', { type: 'success' })
    } catch (error) {
      notify('Something went wrong', { type: 'error' })
    }
    setIsLoading(false)
    handleClose()
  }

  const handleClose = () => {
    setIsOpen(false)
    refresh()
  }

  const handleOpen = () => {
    setIsOpen(true)
  }

  const onPriceInputChange = (value: string) => {
    if(typeof parseFloat(value) === 'number' && !isNaN(parseFloat(value))) {
      setNewPrice(parseFloat(value))
    }
  }

  return <div>
    <CustomButton onClick={handleOpen}>Change Price</CustomButton>
    <Dialog open={isOpen}>
      <DialogTitle>
        Change assignment price
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Input type='number' value={newPrice} onChange={(event) => onPriceInputChange(event.target.value)} startAdornment={<InputAdornment position="start">€</InputAdornment>} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button variant='contained' disabled={newPrice === undefined || newPrice < 0 || isLoading} onClick={handleInterruptAssignment}>
            Submit
        </Button>
      </DialogActions>
    </Dialog>
  </div>
}

export default ChangePrice